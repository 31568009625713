<template>
  <VueDatePicker
    v-model="date"
    auto-apply
    :format-locale="nl"
    format="yyyy-MMMM"
    month-name-format="long"
    month-picker
    :enable-time-picker="false"
    :min-date="minDate"
    :max-date="maxDate"
  ></VueDatePicker>
  <div
    v-if="helperText"
    class="text-sm"
    :class="
      stateError
        ? 'text-danger-600'
        : stateSuccess
          ? 'text-success-600'
          : 'text-gray-500'
    "
  >
    {{ helperText }}
  </div>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker"
import { nl } from "date-fns/locale"

defineProps({
  minDate: {
    type: Date,
    default: null,
  },
  maxDate: {
    type: Date,
    default: null,
  },
  helperText: {
    type: String,
    default: "",
  },
  stateError: {
    type: Boolean,
    default: false,
  },
  stateSuccess: {
    type: Boolean,
    default: false,
  },
})
// eslint-disable-next-line vue/require-prop-types
const date = defineModel()
</script>

<style>
.dp__input {
  @apply w-full overflow-hidden rounded-lg border border-gray-300 bg-white *:px-3 *:py-2 has-[:focus]:border-primary !important;
}

.dp__overlay_cell_active {
  @apply bg-primary !important;
}
</style>
