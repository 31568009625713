<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 7"
    title="Voeg batterij toe"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.batteryStepInvalid"
    :validation-state="wizardValidationStore.v$batteryRules"
    :disabled-next-button="disabledNextButton"
  >
    <template #content>
      <InputSelect
        v-if="!demoStore.active"
        v-model="batteryPreset"
        data-cy="battery-preset"
        :options="batteryOptions"
        label="name"
        placeholder="Kies batterij"
        select-title="Kies een standaard batterij"
        track-by="name"
      />

      <InputField
        v-model="wizardStore.battery.capacity"
        type="number"
        data-cy="battery-capacity"
        label="Capaciteit"
        placeholder="Bijv. 10.000"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$batteryRules.battery.capacity,
            'Capaciteit',
          )
        "
        :state-error="
          !!wizardValidationStore.v$batteryRules.battery.capacity.$errors.length
        "
        @update:model-value="
          wizardValidationStore.v$batteryRules.battery.capacity.$touch()
        "
      >
        <template #addonRight> kWh </template>
      </InputField>
      <InputField
        v-model="wizardStore.battery.power"
        data-cy="battery-power"
        type="number"
        label="Vermogen"
        placeholder="Bijv. 2.000"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$batteryRules.battery.power,
            'Vermogen',
          )
        "
        :state-error="
          !!wizardValidationStore.v$batteryRules.battery.power.$errors.length
        "
        @update:model-value="
          wizardValidationStore.v$batteryRules.battery.power.$touch()
        "
      >
        <template #addonRight> kW </template>
      </InputField>
      <div class="flex flex-col gap-1">
        <InputSelect
          v-model="wizardStore.battery.technology"
          :options="batteryTechnologyOptions"
          data-cy="battery-technology"
          track-by="name"
          select-title="Kies technologie"
          label="name"
          placeholder="Kies technologie"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.battery.technology,
              'Technologie',
            )
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.battery.technology.$errors
              .length
          "
        />
      </div>

      <InputField
        v-model="wizardStore.battery.capex"
        type="string"
        data-cy="battery-capex"
        label="Investeringskosten batterij"
        placeholder="Bijv. 2.000"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$batteryRules.battery.capex,
            'Investeringskosten',
          )
        "
        :state-error="
          !!wizardValidationStore.v$batteryRules.battery.capex.$errors.length
        "
        @update:model-value="
          wizardValidationStore.v$batteryRules.battery.capex.$touch()
        "
      >
        <template #labelSuffix>
          <WikiTooltipComponent path="/bst/wizard/battery-investment" />
        </template>
        <template #addonLeft>
          <BaseIcon name="money-euro-circle" />
        </template>
      </InputField>
      <div class="gap-2">
        <div class="flex items-center">
          <CheckboxItem
            v-model="wizardStore.battery.capexIncludesInstallation"
            data-cy="battery-capex-includes-installation"
          >
            Inclusief de installatiekosten
            <WikiTooltipComponent path="/bst/wizard/installation-costs" />
          </CheckboxItem>
        </div>
        <small v-if="!wizardStore.battery.capexIncludesInstallation">
          Voor eenmalige kosten voor het aansturen van de batterij wordt 10% van
          de investeringskosten aangehouden.
        </small>
      </div>

      <InputField
        v-model="wizardStore.battery.optimizationFeeFactor"
        type="number"
        label="Kosten aansturing en optimalisatie"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$batteryRules.battery.optimizationFeeFactor,
            'Kosten',
          )
        "
        :state-error="
          !!wizardValidationStore.v$batteryRules.battery.optimizationFeeFactor
            .$errors.length
        "
        @update:model-value="
          wizardValidationStore.v$batteryRules.battery.optimizationFeeFactor.$touch()
        "
      >
        <template #labelSuffix>
          <WikiTooltipComponent path="/bst/results/optimization-fee" />
        </template>
        <template #addonRight> % </template>
      </InputField>

      <div class="flex flex-col gap-1">
        <InputField
          v-model="eiaPercentage"
          type="number"
          data-cy="battery-eia-percentage"
          label="EIA toepassing"
          placeholder="Bijv. 10%"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$batteryRules.battery.eiaPercentage,
              'EIA toepassing',
            ) ||
            'Energie-investeringsaftrek is afhankelijk van de winst van de organisatie'
          "
          :state-error="
            !!wizardValidationStore.v$batteryRules.battery.eiaPercentage.$errors
              .length
          "
          @update:model-value="
            wizardValidationStore.v$batteryRules.battery.eiaPercentage.$touch()
          "
        >
          <template #labelSuffix>
            <WikiTooltipComponent path="/bst/wizard/eia" />
          </template>
          <template #addonRight> % </template>
        </InputField>
      </div>

      <RadioButtonList
        v-model="wizardStore.strategy"
        variant="default"
        data-cy="battery-strategy"
        title="Strategie"
        sub-title="Kies de strategie die het beste bij jouw situatie past"
        :items="strategyOptions"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$batteryRules.strategy,
            'Strategie',
          )
        "
        :state-error="
          !!wizardValidationStore.v$batteryRules.strategy.$errors.length
        "
      >
        <template
          v-for="(option, index) in strategyOptions"
          #[option.label]
          :key="index"
        >
          {{ option.label }}
          <WikiTooltipComponent class="ml-1" :path="option.tooltip" />
        </template>
      </RadioButtonList>
    </template>
    <template #nextButton>
      <LoadingWrapper :loading="newSimulationStore.loadingSimulation" compact>
        <div class="flex items-center gap-2">
          <span>Bekijk resultaten</span>
          <BaseIcon name="arrow-right" :filled="false" />
        </div>
      </LoadingWrapper>
    </template>
    <template #summary>
      {{ wizardStore.battery.technology.name }}
      &bull;&nbsp;{{ formatNumber(wizardStore.battery.capacity, 0) }}&nbsp;kWh
      &bull;&nbsp;{{ formatNumber(wizardStore.battery.power, 0) }}&nbsp;kW
    </template>
  </WizardStep>
</template>

<script setup>
import { computed, ref, watch } from "vue"
import { formatNumber } from "@/services/formatterService.js"
import { watchOnce } from "@vueuse/core"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { fetchApiJson } from "@/services/bstApiService"
import {
  batteryTechnologyOptions,
  strategyOptions,
  useWizardStore,
} from "@/stores/wizardStore"
import { useDemoStore } from "@/stores/useDemoStore"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import useNewSimulationStore from "@/stores/newSimulationStore"
import {
  BaseIcon,
  InputField,
  InputSelect,
  LoadingWrapper,
  RadioButtonList,
  CheckboxItem,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /**
   * Used to determine which step is currently active
   */
  isActive: {
    type: Boolean,
    required: true,
  },
  /**
   * For determining wether to show the finished checkmark and summary of the
   * inputfields
   */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to determine if the step is enabled
   */
  isEnabled: {
    type: Boolean,
    default: true,
  },
  disabledNextButton: {
    type: Boolean,
    default: false,
  },
})

const demoStore = useDemoStore()
const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const batteryPreset = ref(null)
const wizardStepRef = ref()
const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

/**
 * Transform eiaPercentage between percentage and fraction
 */
const eiaPercentage = computed({
  get: () => wizardStore.battery.eiaPercentage * 100,
  set: (value) => {
    wizardStore.battery.eiaPercentage = value / 100
  },
})

watch(batteryPreset, (option) => {
  if (option && !demoStore.active) {
    wizardStore.battery.capacity = option.capacity
    wizardStore.battery.power = option.power
    wizardStore.battery.capex = option.price
  }
})

/**
 * Sets the battery type to Lithium the first time a preset is selected
 */
watchOnce(batteryPreset, () => {
  wizardStore.battery.technology = batteryTechnologyOptions[0]
})

/**
 * Loads the battery options from the API the first time this step is activated
 */
const batteryOptions = ref([])
watchOnce(
  () => props.isActive,
  async (isActive) => {
    if (!isActive) {
      return
    }
    batteryOptions.value = await fetchApiJson("/v2/battery-presets/")
  },
)

defineExpose({ getValidationState })
</script>
